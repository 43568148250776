<template>
    <div style="background-color: #ffffff;text-align: left;padding: 10px 10px;border-radius: 5px;">
        <div class="pageCenter text-left m-b-20" style="min-height: 500px;">
            <div class="order_tool_menu">
                <el-button-group>
                    <el-button v-for="v of orderStatus" :key="v.id" @click="setOrderStatusForEnterpriseSearchEnum(v.id)" :type="queryFurnitureLibrary.orderStatusForEnterpriseSearchEnum==v.id?'primary':''" size="mini">{{v.text}}</el-button>
                </el-button-group>
                <div class="order_tool_menu_right">
                  <el-date-picker v-model="searchTime" type="daterange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="mini" style="width: 230px;margin-right: 10px;" />
                  <el-input placeholder="请输入搜索内容" style="width: 280px;" v-model="searchValue" class="input-with-select" size="mini">
                    <el-select v-model="searchType" style="width: 95px;" slot="prepend" placeholder="请选择">
                      <el-option :label="v.text" :value="v.id" v-for="(v,i) of searchTypes" :key="i"></el-option>
                    </el-select>
                    <el-button slot="append" @click="keywordFiltr('search')" icon="el-icon-search"></el-button>
                  </el-input>
                </div>
            </div>
            <div class="" v-if="queryFurnitureLibraryObj.content">
                <order callBack="callBack" v-for="(v,i) of queryFurnitureLibraryObj.content" :orderData="v" :key="i" class="m-t-20" :isShowSet="true" :isBuyer="false"></order>
            </div>
            <div v-else class="p-50 c-2 text-center">还没有订单</div>
        </div>
        <div class="pagination" style="background-color: #ffffff;padding: 20px 0;">
            <el-pagination v-if="queryFurnitureLibraryObj.content" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="queryFurnitureLibraryObj.size" :total="queryFurnitureLibraryObj.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
            </el-pagination>
        </div>
        <el-dialog width="680px" title="审核售后" :visible.sync="dialogVisible">
            <el-form label-width="120px">
                <el-form-item label="售后类型">
                    <el-input disabled v-model="classify.newsAlbumName" autocomplete="off" placeholder="请输入名称"></el-input>
                </el-form-item>
                <el-form-item label="售后原因">
                    <el-input disabled v-model="classify.newsAlbumPhoto" autocomplete="off" placeholder="请输入名称"></el-input>
                </el-form-item>
                <el-form-item label="备注说明">
                    <el-input disabled v-model="classify.newsAlbumPhoto" type="textarea" autocomplete="off" placeholder="请输入名称"></el-input>
                    <!-- <img src="../../assets/logo.png" alt="" v-for="(v,i) of 4" class="dialogImg"> -->
                    <el-image class="dialogImg" :src="url" :preview-src-list="srcList">
                    </el-image>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="">同意</el-button>
                    <el-button @click="dialogVisible = false">拒绝</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { schemeOrder } from '@/service/index.js'
import { localSet, localGet, localDel } from "@/store/store"

export default {
    name: "Enter",
    data() {
        return {
            pageWidth: oucy.pageWidth,
            // 按钮组 0全部 1待支付 2待发货 3待收货 4已收货 5已完成 6待处理 7已取消 8回收站 9已关闭
            orderStatus: [
                { text: '全部', id: 0 },
                { text: '待支付', id: 1 },
                { text: '待发货', id: 2 },
                { text: '待收货', id: 3 },
                { text: '已收货', id: 4 },
                { text: '已完成', id: 5 },
                // { text: '待处理', id: 6 },
                { text: '已取消', id: 7 },
                { text: '回收站', id: 8 }
                // { text: '已关闭', id: 9 },
            ],
            // 搜索
            searchTypes: [
                { text: '订单编号', id: 'orderId' },
                { text: '支付单号', id: 'orderTradeNo' },
                { text: '收货地址', id: 'postAddr' },
                { text: '收货电话', id: 'postPhone' },
                { text: '收货人名', id: 'postUsername' },
                { text: '商品名称', id: 'spuName' },
                { text: '用户名称', id: 'userName' },
            ],
            searchTime: [],
            searchValue: null,
            searchType: 'orderId',

            pageSizes: [0],

            statu: 0,
            input3: null,
            select: null,
            classify: {
                newsAlbumName: "退货退款",
                newsAlbumPhoto: "商家发错商品",
            },
            dialogVisible: !true,
            url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
            srcList: [
                'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
                'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
            ],
            queryFurnitureLibraryObj: {},
            // 请求对象
            queryFurnitureLibrary: {
                enterpriseId: null,
                orderStatusForEnterpriseSearchEnum: 0,
                limit: 5,
                start: 0,
                globalAttrOption: null,
                globalClassId: null,
                goodSort: 0,
                priceMax: null,
                priceMin: null,
            },
        }
    },
    computed: {

    },
    mounted() {
        let enterprise = localGet('enterprise')
        if (enterprise && enterprise.id) {
            this.queryFurnitureLibrary.enterpriseId = enterprise.id
            this.queryOrderForEnterprise()
        }
    },
    methods: {
        keywordFiltr(fn){
            this.$oucy.keywordFiltr(this.searchValue).then(res=>{
               this[fn]() 
            },err=>{})
        },
        setOrderStatusForEnterpriseSearchEnum(v) {
            this.queryFurnitureLibrary.orderStatusForEnterpriseSearchEnum = v
            this.queryOrderForEnterprise()
        },
        // 切换页数
        sizeChange: function(pageSize) {
            const me = this;
            me.queryFurnitureLibrary.limit = pageSize;
            me.queryOrderForEnterprise();
        },
        // 切换页码
        currentChange: function(current) {
            const me = this;
            me.queryFurnitureLibrary.start = current - 1;
            me.queryOrderForEnterprise();
        },
        // 请求
        queryOrderForEnterprise() {
            schemeOrder.queryOrderForEnterprise(this.queryFurnitureLibrary).then(res => {
                this.queryFurnitureLibraryObj = res || {}
            })
        },
        callBack(v) {
            if (v.type == 'go') {
                this.$oucy.go(v.path)
            }
        },
        search() {

            // beginTime   订单创建时间区间[区间开始时间]           

            // endTime 订单创建时间区间[区间截止时间]           

            // orderId 内部订单号          
            // orderTradeNo    第三方支付订单号           
            // postAddr    收货地址           
            // postPhone   收货人联系电话        
            // postUsername    收货人姓名          
            // spuName 商品名称           
            // userName    用户名 
            if (this.searchTime && this.searchTime.length) {
                this.queryFurnitureLibrary.beginTime = this.searchTime[0]
                this.queryFurnitureLibrary.endTime = new Date(this.searchTime[1]).format('yyyy-MM-dd')+' '+'23:59:59'
            } else {
                // if (!this.searchValue) {
                //     this.$message('')
                //     return
                // }
                this.queryFurnitureLibrary.beginTime = null
                this.queryFurnitureLibrary.endTime = null
            }
            this.queryFurnitureLibrary.orderId = null
            this.queryFurnitureLibrary.orderTradeNo = null
            this.queryFurnitureLibrary.postAddr = null
            this.queryFurnitureLibrary.postPhone = null
            this.queryFurnitureLibrary.postUsername = null
            this.queryFurnitureLibrary.spuName = null
            this.queryFurnitureLibrary.userName = null
console.log(this.queryFurnitureLibrary)
            this.queryFurnitureLibrary[this.searchType] = this.searchValue
            this.queryFurnitureLibrary.start =0
            this.queryOrderForEnterprise()
        }
    }
}
</script>
<style scoped lang="less">
.titleBar {
    height: 100px;
    /*background-color: #0366C3;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.titleBar_left {
    /*background-color: #42b983;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.titleBar_left_txt {
    /*background-color: #409EFF;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    font-size: 25px;
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px #eeeeee solid;
    color: gray;
}

.titleBar_right {
    font-size: 25px;
    color: gray;
}

.titleBar_right:hover {
    cursor: pointer;
    color: #000000;
}

.breadcrumbBar {
    padding: 10px 0;
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.breadcrumbBar.enterHeadWidth {
    padding: 0 60px;
}

.el-menu-vertical-demo {
    text-align: left;
    min-height: 700px;
    border-radius: 4px;
}

.el-menu-item,
.el-submenu__title {
    font-size: 16px;
}

.el-menu-item span {
    font-size: 16px;
}

.dialogImg {
    width: 104px;
    height: 104px;
    margin-right: 10px;
    margin-top: 10px;
}

.order_tool_menu{
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.order_tool_menu_right{
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
</style>